

try {
  window.$ = window.jQuery = require('jquery');
  // window.Popper = require('@popperjs/core/dist/esm');
  window.Swal = require('sweetalert2/dist/sweetalert2');
  window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle');
  require('datatables.net-responsive-bs5');


  
} catch (exception) {
 // console.error(exception);
}


// Vanillajs Datepicker

import Datepicker from 'vanillajs-datepicker/Datepicker';
import DateRangePicker from 'vanillajs-datepicker/DateRangePicker';

// Datepicker global verfügbar machen, wenn benötigt
window.Datepicker = Datepicker;
window.DateRangePicker = DateRangePicker; 

import dayjs from 'dayjs';
dayjs.locale('de'); 
window.dayjs = dayjs;

